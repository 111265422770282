import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'; // Import Axios
import { navigate } from 'gatsby';
import { logOut } from '../../Redux/Slice1';


const isBrowser = typeof window !== 'undefined';

const initialState = {
  loading: false,
  status: '',
  access: isBrowser && localStorage.getItem('personalAccess') && JSON.parse(localStorage.getItem('personalAccess')).access || null,
  // refresh: isBrowser && localStorage.getItem('AuthFresh') && JSON.parse(localStorage.getItem('AuthFresh')) || null,
  rejectRefresh: false,
  toggleLog: isBrowser && localStorage.getItem("personalAccess") !== null,
};

// login request
export const login = createAsyncThunk('loginSlice/login', async (arg, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const headers = {
      'Content-Type': 'application/json; charset=utf8',
    };
    const body = JSON.stringify(arg);
    const response = await axios.post('https://ccl.apps.domefdh.com/api/login/', body, {
      headers,
    });

    if (response.status !== 200) {
      throw new Error(response.data.detail || 'Refresh failed');
    }
    return response.data;
  } catch (error) {
   
  }
});

const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    logout: (state, action) => {
      isBrowser && localStorage.clear();
      return {
        ...state,
        access: null,
        refresh: null,
        toggleLog: false,
      };
    },
  },
  extraReducers: (builder) => {
    // PENDING
    builder
      .addCase(login.pending, (state, action) => {
        return {
          ...state,
          loading: true,
        };
      })
      // SUCCESS
      .addCase(login.fulfilled, (state, action) => {
        if (!action.payload.detail) {
          // access
          isBrowser &&
            localStorage.setItem(
              'personalAccess',
              JSON.stringify({
                access: action.payload.access,
              })
            );
          // refresh
          isBrowser &&
            localStorage.setItem(
              'AuthFresh',
              JSON.stringify({
                refresh: action.payload.refresh,
              })
            );
          // navigate(isBrowser && localStorage.getItem('path') == '1' ? '/dashboard' : '/windload');
        }
          navigate('/')
        return {
          ...state,
          access: action.payload.access,
          refresh: action.payload.refresh,
          loading: false,
          toggleLog: true,
        };
      })
      // REJECTED
      .addCase(login.rejected, (state, action) => {
        console.log('LOGIN REJECT', action.error.message);
        return {
          ...state,
          status: action.error.message,
          loading: false,
        };
      });
  },
});

  export const { logout } = loginSlice.actions;
  export default loginSlice.reducer;