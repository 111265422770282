/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react";
import AOS from "aos";
import GoTop from "./GoTop";
import SessionExpired from "../SessionExpired/SessionExpired"
import { useLocation } from "@reach/router"; // Import useLocation
import { useSelector } from "react-redux";

const Layout = ({ children }) => {
  const location = useLocation();
  const expired = useSelector(state => state.sessionSlice.expired)

  React.useEffect(() => {
    AOS.init()
  }, [])

  function shouldShowSessionExpired() {
    const excludedPaths = ["/login", "/sign-up", "/forgot-password"];
    const isExcluded = excludedPaths.some(path => location.pathname.includes(path));
    
    return !isExcluded; 
  }

  return (
    <>
      <main>
      {shouldShowSessionExpired() && expired && <SessionExpired />}

        {children}
        </main>


      <GoTop scrollStepInPx="100" delayInMs="10.50" />
    </>
  )
}

export default Layout
